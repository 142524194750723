import type { FC, ReactNode } from "react";

import {
  Button,
  Dialog as DialogCore,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";

interface DialogProps {
  message: ReactNode;

  onConfirm: () => void;
  open: boolean;
  title: string;
  variant: "error" | "warning" | "info";
}

const icons = {
  error: <ErrorIcon color="error" fontSize="large" />,
  warning: <WarningIcon color="warning" fontSize="large" />,
  info: <InfoIcon color="info" fontSize="large" />,
};

export const OrganizationNotFoundDialog: FC<DialogProps> = (props) => {
  const { message, onConfirm, open, title, variant, ...other } = props;

  return (
    <DialogCore
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
        },
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        {icons[variant]}
        <Typography color="inherit" sx={{ ml: 2 }} variant="inherit">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {message}
        <br />
        <br />
        If you should get access to that page, contact us via{" "}
        <a href="mailto:help@signalos.io">help@signalos.io</a>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onConfirm} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </DialogCore>
  );
};
