import type { FC, ChangeEvent } from "react";

import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Switch,
  ListSubheader,
} from "@material-ui/core";

import { InputField } from "../../components/atoms/InputField";

import { usePopover } from "../../hooks/use-popover";

import { ChevronDown as ChevronDownIcon } from "../../icons/chevron-down";
import { Logout as LogoutIcon } from "../../icons/logout";

import { useAuth0 } from "@auth0/auth0-react";
import { StickyNote2, PrivacyTip } from "@material-ui/icons";
import useStore from "src/hooks/useStore";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
interface Organization {
  id: string;
  name: string;
}

interface AccountPopoverProps {
  currentOrganization: Organization;
  darkMode: boolean;
  onLanguageChange: (newLanguage: "en" | "pl") => void;
  onOrganizationChange: (organizationId: string) => void;
  onSwitchTheme: () => void;
  organizations: Organization[];
}

const languageOptions = {
  en: {
    label: "English",
  },
  pl: {
    label: "Polish",
  },
};

const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const {
    currentOrganization,
    darkMode,
    onLanguageChange,
    onOrganizationChange,
    onSwitchTheme,
    organizations,
    ...other
  } = props;
  const { i18n, t } = useTranslation();
  const store = useStore();
  const navigate = useNavigate();
  const { logout, user, loginWithRedirect } = useAuth0();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleOrganizationChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      await loginWithRedirect({ change_organization_id: event.target.value });
      store.user.setUserOrganizationId(event.target.value);
    } catch (error) {}
  };

  const handleLanguageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onLanguageChange(event.target.value as "en" | "pl");
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout({ returnTo: window.location.origin });
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong");
    }
  };

  const handleSiteChange = async (event: ChangeEvent<HTMLInputElement>) => {
    store.site.setSiteToken(event.target.value);
    navigate(0);
  };

  const handleOpenPrivacyPolicy = () => {
    window.open(
      `https://storage.googleapis.com/acs-assets/privacy-policy-${i18n.language}.pdf`,
      "_blank"
    );
  };

  const handleOpenTermsAndConditions = () => {
    window.open(
      `https://storage.googleapis.com/acs-assets/terms-of-use-${i18n.language}.pdf`,
      "_blank"
    );
  };

  const renderAvatar = () => {
    return (
      <Avatar
        src={user.picture}
        variant="rounded"
        sx={{
          height: 40,
          width: 40,
        }}
      />
    );
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          cursor: "pointer",
          display: "flex",
          ml: 2,
        }}
        {...other}
      >
        {renderAvatar()}
        <Box
          sx={{
            alignItems: "center",
            display: {
              md: "flex",
              xs: "none",
            },
            flex: 1,
            ml: 1,
            minWidth: 120,
          }}
        >
          <div>
            <Typography sx={{ color: "primary.contrastText" }} variant="subtitle2">
              {user.name}
            </Typography>
          </div>
          <ChevronDownIcon
            sx={{
              color: "primary.contrastText",
              ml: 1,
            }}
          />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            minWidth: 260,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <List sx={{ p: 0 }}>
          <ListItem>
            <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
            <ListItemText
              primary={user.email}
              secondary={
                user["https://platform.signalos.io/organizations"]?.find(
                  (el) => el.id === user["https://platform.signalos.io/active_org_id"]
                ).display_name
              }
            />
          </ListItem>
          {user["https://platform.signalos.io/organizations"]?.length > 1 && (
            <ListItem sx={{ p: 0, pb: 2 }}>
              <InputField
                fullWidth
                onChange={handleOrganizationChange}
                select
                SelectProps={{ native: true }}
                value={user["https://platform.signalos.io/active_org_id"] ?? null}
                sx={{
                  px: 2,
                }}
              >
                {user["https://platform.signalos.io/organizations"].map((organization) => (
                  <option key={organization.id} value={organization.id}>
                    {organization.display_name}
                  </option>
                ))}
              </InputField>
            </ListItem>
          )}
          {store.site?.sites?.length > 1 && (
            <ListItem sx={{ p: 0, pb: 2 }}>
              <InputField
                fullWidth
                onChange={handleSiteChange}
                select
                SelectProps={{ native: true }}
                value={store.site.siteToken}
                sx={{
                  px: 2,
                }}
              >
                {store.site.sites.map((site) => (
                  <option key={site.token} value={site.token}>
                    {site?.name}
                  </option>
                ))}
              </InputField>
            </ListItem>
          )}
          <li>
            <List disablePadding>
              <ListSubheader
                sx={{
                  display: {
                    md: "none",
                    xs: "flex",
                  },
                }}
                disableSticky
              >
                App Settings
              </ListSubheader>
              <ListItem
                sx={{
                  display: {
                    md: "none",
                    xs: "flex",
                  },
                }}
              >
                <InputField
                  fullWidth
                  onChange={handleLanguageChange}
                  select
                  SelectProps={{ native: true }}
                  value={i18n.language}
                >
                  {Object.keys(languageOptions).map((option) => (
                    <option key={option} value={option}>
                      {languageOptions[option].label}
                    </option>
                  ))}
                </InputField>
              </ListItem>
              <ListItem
                sx={{
                  py: 0,
                  display: {
                    md: "none",
                    xs: "flex",
                  },
                }}
              >
                <Switch checked={darkMode} onChange={onSwitchTheme} />
                <Typography color="textPrimary" variant="body2">
                  Dark Mode
                </Typography>
              </ListItem>
            </List>
          </li>
          <ListItem button onClick={handleOpenTermsAndConditions}>
            <ListItemIcon>
              <PrivacyTip />
            </ListItemIcon>
            <ListItemText primary={t("common.termsAndConditions")} />
          </ListItem>
          <ListItem button onClick={handleOpenPrivacyPolicy}>
            <ListItemIcon>
              <StickyNote2 />
            </ListItemIcon>
            <ListItemText primary={t("common.privacyPolicy")} />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("common.logout")} />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default observer(AccountPopover);
