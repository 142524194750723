import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        search: "Search",
        all: "All",
        bulkActions: "Bulk Actions",
        noResultsFound: "No results found",
        page: "Page",
        of: "of",
        success: "Success",
        error: "Error",
        from: "From",
        to: "To",
        noData: "No data",
        alreadyInUse: "Already in use",
        assign: "Assign",
        yes: "Yes",
        no: "No",
        areYouSure: "Are You sure",
        logout: "Logout",
        aboutUs: "About us",
        show: "Show",
        actions: "Actions",
        dragNDrop: "Drag 'n' drop some file here, or click to select file",
        perPage: "Per page:",
        availableSoon: "Available soon",
        nextStep: "Next step",
        previousStep: "Previous step",
        noObjectsHere: "There are not objects here yet.",
        privacyPolicy: "Privacy policy",
        termsAndConditions: "Terms and conditions",
        button: {
          save: "Save",
          cancel: "Cancel",
          detach: "Detach",
          delete: "Delete",
          upload: "Upload",
        },
        profileVariants: {
          assigning: "Assigning",
          removing: "Removing",
          synchronizing: "Synchronizing",
          scheduled: "Scheduled",
          inserting_connection_error: "Inserting connection error",
          widthdrawing_connection_error: "Widthdrawing connection error",
          removing_error: "Removing error",
          assigning_error: "Assigning error",
          failed: "Failed",
          max_limit_exhausted: "Max limit exhausted",
          assigned: "Assigned",
          queued_to_remove: "Queued to remove",
          queued_to_assign: "Queued to assign",
        },
        badgesVariants: {
          assigned: "Assigned",
          available: "Available",
          blocked: "Blocked",
          error: "Error",
          other: "Other",
          max_limit_exhausted: "Max limit exhausted",
          new: "New",
          queued: "Queued",
          inserting_connection_error: "Inserting connection error",
          widthdrawing_connection_error: "Widthdrawing connection error",
        },
        operationCodesVariants: {
          update_badge_access_control: "Update badge access control",
          synchronize_carrier_with_digital_twin: "Synchronize carrier with digital twin",
          create_carrier_access_control: "Create carrier access control",
          attach_badge_to_carrier: "Attach badge to carrier",
          detach_badge_from_carrier: "Detach badge from carrier",
          synchronize_attached_badge_with_digital_twin:
            "Synchronize attached badge with digital twin",
          synchronize_detached_badge_with_digital_twin:
            "Synchronize detached badge with digital twin",
          attach_carrier_profiles: "Attach carrier profiles",
          detach_carrier_profiles: "Detach carrier profiles",
          synchronize_attached_profiles_with_digital_twin:
            "Synchronize attached profiles with digital twin",
          synchronize_detached_profiles_with_digital_twin:
            "Synchronize detached profiles with digital twin",
          synchronize_new_site_profiles: "Synchronize new site profiles",
        },
      },
      profiles: {
        title: "Profiles",
        tab: "Profiles | SignalOS",
        assignProfiles: "Assign profiles",
        detachCheckedProfiles: "Detach selected profiles",
        thereAreNoProfilesToAssign: "There are no profiles to assign",
        bulkActions: "Bulk Actions",
        assignSelectedProfiles: "Assign selected profiles",
        profilesUpdated: "Profiles updated",
        fieldRequired: "Required field",
        profile: "Profile",
        profiles: "Profiles",
        edit: "Edit category",
        assign: "Assign building profiles",
        editProfile: "Change building profile",
        friendly: {
          tab: "Friendly profiles | SignalOS",
          title: "Friendly profiles",
          create: "Create friendly profile",
          delete: "Delete",
          dialog: {
            areYouSure: "Are you sure, you want to delete ",
            areYouSureDetach: "Are you sure, you want to detach ",
            profile: " profile?",
            profiles: " profiles?",
            required: "Field is required",
            categories: "Categories:",
          },
          table: {
            name: "Name",
            id: "ID",
            buildingProfiles: "Building profiles",
            category: "Category",
            profilesAssigned: "{{amount}} Assigned profiles",
            noCategory: "No Category",
          },
        },
        building: {
          tab: "Building profiles | SignalOS",
          title: "Building profiles",
        },
        columns: {
          profileId: "Profile id",
          name: "Name",
          type: "Type",
          accessControlId: "Access control id",
          createdAt: "Created at",
          updatedAt: "Updated at",
          isDefault: "Is default",
          isInUseByCarrier: "Is in use by carrier",
        },
        assignDialog: {
          title: "Assign selected profile to organization",
        },
        toast: {
          profileDetachedError: "Profile can't be detached because it is in use",
          profileDetachSuccess: "Profiles detached successfully",
          profilesAssignedSuccess: "Profiles assigned successfully",
          categoryChangeSuccess: "Category changed successfully",
          profileDeleteSuccess: "Friendly profile deleted successfully",
          profileNameSuccess: "Name changed successfully",
        },
      },
      badges: {
        title: "Badges",
        tab: "Badges | SignalOS",
        assignBadge: "Assign badge",
        uploadFile: "Upload file with badges",
        createBadge: "Create badge",
        columns: {
          type: "Type",
          createdAt: "Created at",
          updatedAt: "Updated at",
          isInUse: "Is in use",
          accessControls: "Access controls",
          assignedTo: "Assigned to",
        },
        createDialog: {
          title: "Create badge",
          name: "Badge name",
          type: "Type",
          requiredField: "Field is required",
          assignOrganization: "Assign organization",
        },
        assignBadgeDialog: {
          title: "Assign badge to organization",
          requiredField: "Field is required",
        },
        deleteDialog: {
          message: "Do You want to delete badge: ",
          message2: "This cant be undone",
        },
        detachDialog: {
          message: "Do You want to detach badge: ",
        },
        uploadDialog: {
          title: "Upload file with badges (xlsx)",
          download: "Download template",
          requiredField: "Field is required",
          assignOrganization: "Assign organization",
          type: "Type",
        },
        filters: {
          title: "Filter by assigned organization",
          organizations: "Organizations",
          inUse: "Is in use",
          false: "No",
          true: "Yes",
        },
        toast: {
          badgeCreated: "Badge created",
          badgeDeleted: "Badge deleted",
          badgeAssigned: "Badge assigned",
          badgeDetached: "Badge detached",
          badgeUploaded: "Badge uploaded",
          badgeDetachedError: "Badge can't be detached because it is in use",
        },
      },
      createProfile: {
        tab: "Create profile | SignalOS",
        title: "Create profile",
        fieldRequired: "Field is required",
        stepper: {
          step: "Step ",
          profileName: "Profile name",
          categories: "Categories",
          buildingProfile: "Building profiles",
          confirmation: "Confirmation",
          name: "Name",
          nextStep: "Next step",
          back: "Back",
          confirm: "Confirm",
          success: "Profile created successfully",
        },
      },
      categories: {
        tab: "Categories | SignalOS",
        title: "Categories",
        add: "Add Category",
        category: "Category",
        assign: "Assign friendly profiles",
        table: {
          id: "ID",
          name: "Name",
          delete: "Delete",
          profiles: "Friendly profiles",
        },
        toast: {
          success: "Category added successfully",
          delete: "Category deleted successfully",
          error: "You can't delete a category that has profiles assigned to it.",
        },
        dialog: {
          title: "Add category",
          name: "Name: ",
          form: {
            name: "Category name",
            type: "Category type",
            profiles: "Friendly profiles: ",
            required: "Field is required",
          },
          areYouSure: "Are you sure you want to delete ",
          alreadyAssigned: "Already assigned to: ",
          category: " category?",
        },
      },
      organizations: {
        title: "Organizations",
        tab: "Organizations | SignalOS",
        columns: {
          name: "Name",
          createdAt: "Created at",
          updatedAt: "Updated at",
          manageOrganization: "Manage organization",
          manageBadges: "Mange badges",
          manageProfiles: "Mange profiles",
          cardsUsage: "Badges",
        },

        profiles: {
          title: "- profiles",
          tab: "Organization profiles | SignalOS",
          backToOrg: "Back to organizations",
          assignProfiles: "Assign profiles",
          doYouWantToDetach: "Do You want to detach profile:",
        },
      },
      organization: {
        title: "Organization",
        tabs: {
          badges: "Badges",
          profiles: "Profiles",
          categories: "Categories",
        },
        badges: {
          title: "Badges",
          doYouWantToDetach: "Do You want to detach badge:",
        },
        profiles: {
          title: "Profiles",
          assignProfiles: "Assign profiles",
          doYouWantToDetach: "Do You want to detach profile:",
        },
        categories: {
          title: "Categories",
          toast: {
            success: "Profiles updated successfully",
            categoriesAttachSuccess: "Profiles attach successfully",
            categoriesDetachSuccess: "Profiles detach successfully",
          },
        },
      },
    },
  },
  pl: {
    translation: {
      common: {
        search: "Szukaj",
        all: "Wszystkie",
        bulkActions: "Wybierz",
        noResultsFound: "Nie znaleziono wyników",
        page: "Strona",
        of: "z",
        success: "Sukces",
        error: "Błąd",
        noData: "Brak danych",
        alreadyInUse: "Aktualnie w użyciu",
        assign: "Przypisz",
        yes: "Tak",
        no: "Nie",
        areYouSure: "Czy na pewno?",
        logout: "Wyloguj",
        aboutUs: "O nas",
        show: "Pokaż",
        actions: "Akcje",
        dragNDrop: "Przeciągnij swój plik tutaj, lub kliknij i wybierz",
        perPage: "Na stronę:",
        availableSoon: "Dostępne wkrótce",
        nextStep: "Następny krok",
        previousStep: "Poprzedni krok",
        noObjectsHere: "Nie ma tu jeszcze żadnych obiektów.",
        privacyPolicy: "Polityka prywatności",
        termsAndConditions: "Regulamin",
        button: {
          save: "Zapisz",
          cancel: "Anuluj",
          detach: "Odpisz",
          delete: "Usuń",
          upload: "Załaduj",
        },
        profileVariants: {
          assigning: "Przypisywanie",
          removing: "Usuwanie",
          synchronizing: "Synchronizacja",
          scheduled: "Zaplanowany",
          inserting_connection_error: "Błąd podczas wstawiania połączenia",
          widthdrawing_connection_error: "Błąd połaczenia",
          removing_error: "Błąd przy usuwaniu",
          assigning_error: "Błąd przy przypisywaniu",
          failed: "Błąd",
          max_limit_exhausted: "Maksymalna ilośc prób osiągnięta",
          assigned: "Przypisany",
          queued_to_remove: "Zakolejkowany do usunięcia",
          queued_to_assign: "Zakolejkowany do przypisania",
        },
        badgesVariants: {
          assigned: "Przypisany",
          available: "Dostępny",
          blocked: "Zablokowany",
          error: "Błąd",
          other: "Inny",
          max_limit_exhausted: "Maksymalna ilość prób osiągnięta",
          new: "Nowy",
          queued: "Zakolejkowany",
          inserting_connection_error: "Błąd przy wczytywaniu",
          widthdrawing_connection_error: "Błąd przy połączeniu",
        },
      },
      profiles: {
        title: "Profile",
        tab: "Profile | SignalOS",
        assignProfiles: "Przypisz profile",
        detachCheckedProfiles: "Odczep zaznaczone profile",
        thereAreNoProfilesToAssign: "Brak profili do przypisania",
        bulkActions: "Działania Masowe",
        assignSelectedProfiles: "Przypisz wybrane profile",
        profilesUpdated: "Profile zaktualizowane",
        fieldRequired: "Pole wymagane",
        profiles: "Profile",
        profile: "Profil",
        edit: "Edytuj kategorie",
        assign: "Przypisz profile budynkowe",
        editProfile: "Zmień profil budynkowy",

        friendly: {
          tab: "Przyjazne profile | SignalOS",
          title: "Przyjazne profile",
          create: "Utwórz przyjazny profil",
          delete: "Usuń",
          dialog: {
            areYouSure: "Czy na pewno, chcesz usunąć ",
            profile: " profil?",
            areYouSureDetach: "Czy na pewno, chcesz odpiąć ",
            profiles: " profili?",
            required: "Pole jest wymagane",
            categories: "Kategorie:",
          },
          table: {
            name: "Nazwa",
            id: "Identyfikator",
            buildingProfiles: "Profile budynkowe",
            category: "Kategoria",
            profilesAssigned: "{{amount}} Przypisanych profili",
            noCategory: "Brak kategorii",
          },
        },
        building: {
          tab: "Profile budynkowe | SignalOS",
          title: "Profile budynkowe",
        },
        columns: {
          profileId: "Id profilu",
          name: "Nazwa",
          type: "Typ",
          state: "Stan",
          from: "Od",
          to: "Do",
          accessControlId: "Id kontroli dostępu",
          createdAt: "Utworzony",
          updatedAt: "Zaktualizowany",
          isDefault: "Jest domyślny",
          isInUseByCarrier: "Jest używany",
        },
        assignDialog: {
          title: "Przypisz wybrane profile do organizacji",
        },
        toast: {
          profileDetachedError: "Profil nie może zostać odpięty, ponieważ jest w użyciu",
          profileDetachSuccess: "Profile odpięte pomyślnie",
          profilesAssignedSuccess: "Profile przypisane pomyślnie",
          categoryChangeSuccess: "Kategoria zmieniona pomyślnie",
          profileDeleteSuccess: "Przyjazny profil usunięty pomyślnie",
          profileNameSuccess: "Nazwa zmieniona pomyślnie",
        },
        editDialog: {
          title: "Edit building profiles",
        },
      },
      badges: {
        title: "Identyfikatory",
        tab: "Identyfikatory | SignalOS",
        assignBadge: "Przypisz identyfikator",
        uploadFile: "Wgraj plik z identyfikatorami",
        createBadge: "Stwórz identyfikator",
        columns: {
          type: "Typ",
          createdAt: "Utworzona",
          updatedAt: "Zaktualizowana",
          isInUse: "Jest w użyciu",
          accessControls: "Kontrola dostępu",
          assignedTo: "Przypisana do",
        },
        createDialog: {
          title: "Stwórz identyfikator",
          name: "Nazwa identyfikatora",
          type: "Typ",
          requiredField: "Pole jest wymagane",
          assignOrganization: "Przypisz organizacje",
        },
        assignBadgeDialog: {
          title: "Przypisz identyfikator do organizacji",
          requiredField: "Pole jest wymagane",
        },
        deleteDialog: {
          message: "Czy na pewno chcesz usunąć identyfikator: ",
          message2: "Tego nie da się cofnąć",
        },
        detachDialog: {
          message: "Czy na pewno chcesz odczepić identyfikator: ",
        },
        uploadDialog: {
          title: "Wgraj plik z identyfikatorami (xlsx)",
          download: "Ściągnij wzór pliku",
          requiredField: "Pole jest wymagane",
          assignOrganization: "Przypisz organizacje",
          type: "Typ",
        },
        filters: {
          title: "Filtruj po przypisanej organizacji",
          organizations: "Organizacje",
          inUse: "W użyciu",
          false: "Nie",
          true: "Tak",
        },
        toast: {
          badgeCreated: "Identyfikator stworzony",
          badgeDeleted: "Identyfikator usunięty",
          badgeAssigned: "Identyfikator przypisany",
          badgeDetached: "Identyfikator odpięty",
          badgeUploaded: "Identyfikatory załadowane",
          badgeDetachedError: "Identyfikator nie może zostać odpięty, ponieważ jest w użyciu",
        },
      },
      createProfile: {
        tab: "Utwórz profil | SignalOS",
        title: "Utwórz profil",
        fieldRequired: "Pole jest wymagane",
        stepper: {
          step: "Krok ",
          profileName: "Nazwa profilu",
          categories: "Kategorie",
          buildingProfile: "Profile budynkowe",
          confirmation: "Potwierdzenie",
          name: "Nazwa",
          nextStep: "Następny krok",
          back: "Wróć",
          confirm: "Potwierdź",
          success: "Profil utworzony pomyślnie",
        },
      },
      categories: {
        tab: "Kategorie | SignalOS",
        title: "Kategorie",
        add: "Dodaj kategorie",
        category: "Kategoria",
        assign: "Przypisz przyjazne profile",
        table: {
          id: "Identyfikator",
          name: "Nazwa",
          delete: "Usuń",
          profiles: "Friendly profiles",
        },
        toast: {
          success: "Kategoria dodana pomyślnie",
          delete: "Kategoria usunięta pomyślnie",
          error: "Nie możesz usunąć kategorii, która ma przypisane profile.",
        },
        dialog: {
          title: "Dodaj kategorie",
          name: "Nazwa: ",
          form: {
            name: "Nazwa kategorii",
            type: "Typ kategorii",
            profiles: "Przyjazne profile: ",
            required: "Pole jest wymagane",
          },
          areYouSure: "Czy na pewno chcesz usunąć ",
          category: " kategorie?",
          alreadyAssigned: "Juz przypisany do: ",
        },
      },
      organizations: {
        title: "Organizacje",
        tab: "Organizacje | SignalOS",
        columns: {
          name: "Nazwa",
          createdAt: "Utworzona",
          updatedAt: "Uaktualniona",
          manageOrganization: "Zarządzaj organizacją",
          manageBadges: "Zarządzaj identyfikatorami",
          manageProfiles: "Zarządzaj profilami",
          cardsUsage: "Identyfikatory",
        },
      },
      organization: {
        title: "Organizacja",
        tabs: {
          badges: "Identyfikatory",
          profiles: "Profile",
          categories: "Kategorie",
        },
        badges: {
          title: "Identyfikatory",
          doYouWantToDetach: "Czy na pewno chcesz odpiąć identyfikator:",
        },
        profiles: {
          title: "Profile",
          assignProfiles: "Przypisz profile",
          doYouWantToDetach: "Czy na pewno chcesz odpisać profil:",
        },
        categories: {
          title: "Kategorie",
          toast: {
            success: "Profile zaktualizowane pomyślnie",
            categoriesAttachSuccess: "Profile przypisane pomyślnie",
            categoriesDetachSuccess: "Profile odpisane pomyślnie",
          },
        },
      },
    },
  },
};

export const initializeI18n = (lng: string): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
