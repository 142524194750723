import { useEffect, useState } from "react";
import type { FC, ElementType } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { Box, Divider, Drawer, IconButton, List } from "@material-ui/core";
import { Scrollbar } from "../../components/atoms/Scrollbar";
import { SidebarItem } from "../../components/molecules/SidebarItem";
import { Logo } from "../../components/atoms/Logo";
import { ChevronLeft as ChevronLeftIcon } from "../../icons/chevron-left";
import { ChevronRight as ChevronRightIcon } from "../../icons/chevron-right";

import { Badge, CorporateFare, RoomPreferences, Category } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  onPin: () => void;
  pinned: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

export const Sidebar: FC<SidebarProps> = (props) => {
  const { onPin, pinned } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState("");
  const [hovered, setHovered] = useState(false);

  const items: Item[] = [
    {
      icon: CorporateFare,
      title: t("organizations.title"),
      href: "/organizations",
    },
    { icon: RoomPreferences, title: t("profiles.title"), href: "/profiles" },
    { icon: Badge, title: t("badges.title"), href: "/badges" },
    { icon: Category, title: t("categories.title"), href: "/categories" },
  ];

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = pathname.startsWith(item.href);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      open
      // sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => {
          setHovered(true);
        },
        onMouseLeave: () => {
          setHovered(false);
        },
        sx: {
          backgroundColor: "background.paper",
          height: "calc(100% - 64px)",
          overflowX: "hidden",
          top: 64,
          zIndex: 1,
          transition: "width 250ms ease-in-out",
          width: pinned ? 270 : 73,
          "& .simplebar-content": {
            height: "100%",
          },
          "&:hover": {
            width: 270,
            "& span, p": {
              display: "flex",
            },
          },
        },
      }}
    >
      <Scrollbar
        style={{
          display: "flex",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
          }}
        >
          <List disablePadding>
            {items.map((item) => (
              <SidebarItem
                active={activeItem?.title === item.title}
                activeHref={activeHref}
                key={item.title}
                onOpen={() => handleOpenItem(item)}
                open={openedItem?.title === item.title && (hovered || pinned)}
                pinned={pinned}
                {...item}
              />
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box
            sx={{
              pt: 1,
              mr: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Logo height={17} width={70} />
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};
