import "simplebar/dist/simplebar.min.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { SettingsProvider } from "./contexts/settings-context";
import { App } from "./app";
import { Auth0Provider } from "@auth0/auth0-react";

import ApplicationStoreProvider from "./contexts/ApplicationStoreContext";

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <Auth0Provider
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        cacheLocation="localstorage"
      >
        <BrowserRouter>
          <ApplicationStoreProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <App />
                <Toaster position="bottom-right" />
              </SettingsProvider>
            </LocalizationProvider>
          </ApplicationStoreProvider>
        </BrowserRouter>
      </Auth0Provider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);
