import { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "./layout/MainLayout";

import { LoadingScreen } from "./components/LoadingScreen";

import AuthGuard from "./components/Guards/AuthGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const BadgesPage = Loadable(
  lazy(() =>
    import("./containers/badges").then((module) => ({
      default: module.default,
    }))
  )
);

const Organizations = Loadable(
  lazy(() =>
    import("./containers/organizations").then((module) => ({
      default: module.default,
    }))
  )
);
const Organization = Loadable(
  lazy(() =>
    import("./containers/organizations/[organizationId]").then((module) => ({
      default: module.OrganizationContainer,
    }))
  )
);

const OrganizationBadges = Loadable(
  lazy(() =>
    import("./containers/organizations/badges").then((module) => ({
      default: module.default,
    }))
  )
);

const OrganizationCategories = Loadable(
  lazy(() =>
    import("./containers/organizations/categories").then((module) => ({
      default: module.OrganizationCategoriesContainer,
    }))
  )
);

const Profiles = Loadable(
  lazy(() =>
    import("./containers/profiles").then((module) => ({
      default: module.ProfilesContainer,
    }))
  )
);
const Profile = Loadable(
  lazy(() =>
    import("./containers/profiles/[profileId]").then((module) => ({
      default: module.ProfileContainer,
    }))
  )
);

const CreateProfile = Loadable(
  lazy(() =>
    import("./containers/profiles/create").then((module) => ({
      default: module.CreateProfileContainer,
    }))
  )
);
const Categories = Loadable(
  lazy(() =>
    import("./containers/categories").then((module) => ({
      default: module.CategoriesContainer,
    }))
  )
);
const Category = Loadable(
  lazy(() =>
    import("./containers/categories/[categoryId]").then((module) => ({
      default: module.CategoryContainer,
    }))
  )
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import("./containers/NotFound").then((module) => ({ default: module.NotFound })))
);

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/organizations" replace />,
  },
  {
    path: "badges",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <BadgesPage />,
      },
    ],
  },
  {
    path: "organizations",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Organizations />,
      },
      {
        path: ":organizationId",
        element: <Organization />,
        children: [
          {
            path: "badges",
            element: <OrganizationBadges />,
          },

          {
            path: "profiles",
            element: <OrganizationCategories />,
          },
        ],
      },
    ],
  },
  {
    path: "profiles",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Profiles />,
      },
      {
        path: ":profileId",
        element: <Profile />,
      },
      {
        path: "/create",
        element: <CreateProfile />,
      },
    ],
  },
  {
    path: "categories",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Categories />,
      },
      {
        path: ":categoryId",
        element: <Category />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
